<template>
  <div>
    <evaluation-search-report
      :is-new-report-sidebar-active.sync="isNewReportSidebarActive"
      :feature-options="features"
      :template-options="templates"
      :filter="filter"
    />

    <!-- Filter Card -->
    <b-card
      no-body
      class="m-2 p-2"
    >
      <!-- Add Item Button -->
      <b-col
        cols="12"
        class="d-flex align-items-center"
      >
        <feather-icon
          icon="ChevronDownIcon"
          size="21"
          class="mr-2"
        />
        <span class="font-weight-bold">بحث متقدم</span>
      </b-col>
      <hr>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="النماذج"
            label-for="templates"
          >
            <v-select
              v-model="selectedModel"
              dir="rtl"
              :options="models"
              :clearable="true"
              input-id="client"
              @input="onModelSelected"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="اسم المالك">
            <b-form-input v-model="filter.owner_name" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="الرقم المرجعي">
            <b-form-input v-model="filter.reference" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="رقم الصك">
            <b-form-input v-model="filter.instrument_number" />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="حالة التقييم">
            <v-select
              v-model="filter.status"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="evaluationRequestsTypes"
              :reduce="(val) => val.value"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="التاريخ من-إلى">
            <flat-pickr
              v-model="filter.date"
              :config="config"
              value=""
              class="form-control invoice-edit-input"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="المنطقة"
            label-for="region"
          >
            <v-select
              v-model="filter.region"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="regions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="region"
              @input="regionChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="المدينة"
            label-for="city"
          >
            <v-select
              v-model="filter.city"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="cities"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="city"
              @input="cityChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="الحي"
            label-for="district"
          >
            <v-select
              v-model="filter.district"
              dir="rtl"
              :options="districts"
              :reduce="(val) => val.value"
              :disabled="districts.length == 0"
              :clearable="false"
              input-id="district"
              @input="districtChanged"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="4">
          <b-form-group
            label="نوع العقار"
            label-for="property_classification_id"
          >
            <v-select
              v-model="filter.property_classification_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="classifications"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="property_classification_id"
              @input="propertyClassificationChanged"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label=" نوع العقار الفرعي"
            label-for="property_type_id"
          >
            <v-select
              v-model="filter.property_type_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="filteredTypes"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="property_type_id"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-sm"
          variant="primary"
          @click="addItem"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>إضافة فيلتر</span>
        </b-button>
      </b-col>
      <!-- List of Filter Items -->
      <div
        v-for="(filter, index) in filterItems"
        :key="index"
      >
        <b-row>
          <!-- Select of Feature -->
          <b-col>
            <b-form-group label="اختر مجموعة">
              <v-select
                v-model="filter.group"
                :options="featuresGroups"
              />
            </b-form-group>
          </b-col>

          <!-- Empty Input 1 -->
          <b-col>
            <b-form-group label="اختر حقل">
              <v-select
                v-model="filter.feature"
                :options="getFilteredFeatures(filter.group)"
              />
            </b-form-group>
          </b-col>

          <!-- Select for Operators -->
          <b-col>
            <b-form-group label="المقارن">
              <v-select
                v-model="filter.operator"
                :options="operators"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="القيمة">
              <!-- Use conditional rendering based on the value_type -->
              <component
                :is="getInputType(filter.value_type)"
                v-if="filter.value_type !== 'enum' && filter.value_type !== 'range'"
                v-model="filter.value"
              />
              <v-select
                v-if="filter.value_type === 'enum'"
                v-model="filter.value"
                :close-on-select="!filter.feature.value_options.multiple"
                :multiple="filter.feature.value_options.multiple"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="filter.feature.value_options.options"
                :clearable="true"
                input-id="client"
              />
              <b-form-input
                v-if="
                  filter.feature.value_type === 'range' &&
                    filter.feature.value_options &&
                    filter.feature.value_options.min !== undefined &&
                    filter.feature.value_options.max !== undefined
                "
                v-model="filter.value"
                type="number"
                :min="filter.feature.value_options.min"
                :max="filter.feature.value_options.max"
              />
              <flat-pickr
                v-if="
                  filter.feature.value_type === 'range' &&
                    (!filter.feature.value_options ||
                      filter.feature.value_options.min === undefined ||
                      filter.feature.value_options.max === undefined)
                "
                v-model="filter.value"
                :config="{ mode: 'range' }"
                class="form-control flat-picker bg-transparent border-0 shadow-none"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Item Button -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>حدف</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <br>
      <b-col cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn-sm"
          variant="primary"
          :style="{ width: '150px', fontSize: '18px' }"
          @click="search"
        >
          <span>بحث</span>
        </b-button>
      </b-col>
    </b-card>
    <b-card
      no-body
      class="m-2 p-2"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page Selector -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>عرض</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="true"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>مدخلات</label>
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="isNewReportSidebarActive = true"
              >
                <span class="text-nowrap">تصدير</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table to Display Evaluations -->
      <b-table
        ref="refEvaluationListTable"
        class="position-relative"
        :items="fetchEvaluationsByQuery"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="لم يتم العثور على سجلات مطابقة"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Customize the table cell as needed -->
        <template #cell(actions)="data">
          <!-- Actions Dropdown Menu -->
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- Dropdown Items for Actions -->
            <b-dropdown-item
              v-if="$can('inspect', 'evaluation')"
              :to="{
                name: 'evaluations-review',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>
            <!-- Add more dropdown items as needed -->
          </b-dropdown>
        </template>
      </b-table>

      <!-- Pagination and Item Count -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              عرض من {{ dataMeta.from }} إلى {{ dataMeta.to }} من {{ dataMeta.of }} مدخلات
            </span>
          </b-col>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <!-- Pagination Control -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalEvaluation"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <!-- Previous and Next Icons -->
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
  BPagination,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import flatPickr from 'vue-flatpickr-component';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import evaluationStore from '../evaluationStore';
import useEvaluationSearch from './useEvaluationSearch';
import EvaluationSearchReport from './EvaluationSearchReport.vue';

export default {
  components: {
    EvaluationSearchReport,
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BPagination,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      selectedModel: null,
      filterItems: [],
      operators: [
        { value: '=', label: 'يساوي' },
        { value: 'IN', label: 'ضمن' },
        { value: 'NOT IN', label: 'ليس ضمن' },
        { value: 'LIKE', label: 'يشبه' },
        { value: '<', label: 'أصغر' },
        { value: '>', label: 'أكبر' },
        { value: '<=', label: 'أصغر أو يساوي' },
        { value: '>=', label: 'أكبر أو يساوي' },
        { value: '!=', label: 'مخالف' },
      ], // Initialize with one row
      config: {
        locale: Arabic,
        mode: 'range',
      },
    };
  },
  watch: {
    // Watch for changes to the 'feature' property in filterItems
    filterItems: {
      deep: true,
      handler(newFilterItems, oldFilterItems) {
        newFilterItems.forEach((filterItem, index) => {
          if (filterItem.feature) {         
            // Update the 'value_type' property of the filter item
            this.$set(
              this.filterItems[index],
              'value_type',
              filterItem.feature.value_type,
            );
          }
        });
      },
    },
  },
  methods: {
    search() {
      try {
        this.filter.features = this.filterItems.map((filter) => ({
          id: filter.feature.value,
          operator: filter.operator.value,
          value: filter.value,
        }));
        this.refetchData();
      } catch (error) {
        console.error('Error searching for evaluations:', error);
      }
    },
    onModelSelected() {
      if (this.selectedModel) {
        this.filter = this.selectedModel.filter;
        this.fetchDistricts(this.filter.city_id);
        this.filterItems = this.selectedModel.filter.features;
      }
    },

    fetchAllFeatures() {
      // Retrieve related features
      store
        .dispatch('evaluation/fetchAllFeatures')
        .then((response) => {
          const { data } = response.data;
          const featureOptions = [];
          data.groups.forEach((group) => {
            group.features.forEach((feature) => {
              featureOptions.push({
                value: feature.id,
                label: feature.display_name,
              });
            });
          });
          this.features = featureOptions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addItem() {
      this.filterItems.push({
        group: '',
        feature: '',
        operator: '',
        value: '',
        value_type: 'text',
      }); // Add a new filter item
    },
    removeItem(index) {
      // if (this.filterItems.length > 1) {
      this.filterItems.splice(index, 1); // Remove the filter item at the specified index
      // }
    },

    getFilteredFeatures(selectedGroup) {
      if (selectedGroup) {
        // Map the features of the selected group to a new array
        return selectedGroup.features.map((feature) => ({
          value: feature.id,
          label: feature.display_name,
          value_options: feature.value_options,
          value_type: feature.value_type,
        }));
      }

      // If no group is selected or the group is not found, return an empty array
      return [];
    },
    getInputType(value_type) {
      switch (value_type) {
        case 'text':
          return 'b-form-input'; // Use a text input
        case 'date':
          return 'b-form-date-picker'; // Use a date picker input
        case 'enum':
          return 'v-select'; // Use the v-select component
        case 'range':
          return 'flat-pickr'; // Use the flat-pickr component for date range
        default:
          return 'b-form-input'; // Use a default text input if value_type is not recognized
      }
    },
    fetchCities(region_id) {
      this.isLoading = true;
      this.$store
        .dispatch('evaluation/fetchCitiesByRegion', { id: region_id })
        .then((response) => {
          const { data } = response.data;
          this.cities = data.map((filter) => ({
            label: filter.name_ar,
            value: filter.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      this.$store
        .dispatch('evaluation/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    regionChanged(region) {
      this.fetchCities(region);
      this.filter.city_id = null;
      this.filter.region_id = region;
    },
    cityChanged(city) {
      this.filter.city_id = city;
      this.fetchDistricts(city);
    },
    districtChanged(district) {
      this.filter.district = district;
    },
    propertyClassificationChanged(id) {
      this.filter.property_type_id = null;
      this.filteredTypes = this.types.filter(
        (type) => type.property_classification_id === id,
      );
    },
  },

  setup() {
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';
    const featuresGroups = ref([]);
    const features = ref([]);
    const templates = ref([]);
    const models = ref([]);

    const resetFilter = () => {
      filter.value = JSON.parse(JSON.stringify(blankFilter));
    };
    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.registerModule(EVALUATION_STORE_MODULE_NAME, evaluationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });

    // Retrieve related features
    store
      .dispatch('evaluation/fetchAllFeatures')
      .then((response) => {
        const { data } = response.data;

        // Populate featuresGroups from the groups in the response
        data.map((group) => {
          featuresGroups.value.push({
            value: group.id,
            label: group.display_name,
            features: group.features,
          });
        });

        // Populate features from the features in the response
        data.flatMap((group) => group.features.map((feature) => {
          features.value.push({
            value: feature.id,
            label: feature.display_name,
            value_options: feature.value_options,
            value_type: feature.value_type,
          });
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    // Retrieve related templates
    store
      .dispatch('evaluation/fetchAllTemplates')
      .then((response) => {
        const { data } = response.data;

        // Populate templates from the groups in the response
        data.map((template) => {
          templates.value.push({
            value: template.id,
            label: template.name,
            features: template.features,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // Retrieve related models
    store
      .dispatch('evaluation/fetchAllModels')
      .then((response) => {
        const { data } = response.data;

        // Populate models from the groups in the response
        data.map((model) => {
          models.value.push({
            value: model.id,
            label: model.name,
            filter: model.filter,
          });
        });
      })
      .catch((error) => {
        console.log(error);
      });
    const regions = ref([]);
    const res = store
      .dispatch('evaluation/fetchRegions')
      .then((response) => {
        const { data } = response.data;
        regions.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });

    const cities = ref([]);
    store
      .dispatch('evaluation/fetchCities')
      .then((response) => {
        const { data } = response.data;
        cities.value = data.map((c) => ({
          label: c.name_ar,
          value: c.id,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    const evaluationRequestsTypes = ref([]);
    store
      .dispatch('evaluation/fetchEvaluationRequestsTypes')
      .then((response) => {
        const { data } = response.data;
        evaluationRequestsTypes.value = data.map((c) => ({
          label: c.label,
          value: c.value,
          ...c,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    const districts = ref([]);
    const types = ref([]);
    const filteredTypes = ref([]);
    const classifications = ref([]);

    store.dispatch('evaluation/fetchContractOptions').then((response) => {
      const { propertyTypes, propertyClassifications } = response.data;
      types.value = propertyTypes.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
      filteredTypes.value = types.value;

      classifications.value = propertyClassifications.map((c) => ({
        label: c.name,
        value: c.id,
        ...c,
      }));
    });
    const {
      fetchEvaluationsByQuery,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationListTable,
      refetchData,
      statusFilter,
      filter,
    } = useEvaluationSearch();
    const isNewReportSidebarActive = ref(false);

    return {
      evaluationRequestsTypes,
      isNewReportSidebarActive,
      fetchEvaluationsByQuery,
      tableColumns,
      perPage,
      currentPage,
      totalEvaluation,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refEvaluationListTable,
      refetchData,
      statusFilter,
      filter,
      types,
      filteredTypes,
      classifications,
      regions,
      cities,
      districts,
      templates,
      features,
      featuresGroups,
      models,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
