<template>
  <b-sidebar
    id="new-report-sidebar"
    :visible="isNewReportSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-new-report-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          تصدير تقرير جديد
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--  templates -->
          <validation-provider
            #default="validationContext"
            name="templates"
            rules="required"
          >
            <b-form-group
              label="النماذج"
              label-for="templates"
            >
              <v-select
                v-model="selectedTemplate"
                dir="rtl"
                :options="templateOptions"
                :clearable="true"
                input-id="client"
                @input="onTemplateSelected"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!--  columns -->
          <validation-provider
            #default="validationContext"
            name="columns"
            rules="required"
          >
            <b-form-group
              label="الأعمدة"
              label-for="columns"
            >
              <v-select
                v-model="exportData.features"
                multiple="multiple"
                dir="rtl"
                :options="featureOptions"
                :clearable="true"
                input-id="client"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="fileType"
            rules="required"
          >
            <b-form-group
              label="نوع الملف"
              label-for="fileType"
            >
              <v-select
                v-model="exportData.fileType"
                dir="rtl"
                :options="fileTypes"
                :clearable="true"
                input-id="client"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              تصدير
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              إلغاء
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isNewReportSidebarActive',
    event: 'update:is-new-report-sidebar-active',
  },
  props: {
    isNewReportSidebarActive: {
      type: Boolean,
      required: true,
    },
    featureOptions: {
      type: Array,
      required: true,
    },
    templateOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTemplate: null,
      required,
      alphaNum,
      validEmail,
    };
  },
  methods: {
    onTemplateSelected() {
      if (this.selectedTemplate) {
        this.exportData.features = this.selectedTemplate.features;
      }
    },
    async onSubmit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          const data = { ...this.exportData };
          this.$toast.info('جاري التنزيل...', {
            autoHide: false,
            appendToast: ToastificationContent,
          });
          const mime = data.fileType === 'pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          store
            .dispatch('evaluation/exportReport', { data, mime })
            .then(async (response) => {
              this.$emit('refetch-data');
              this.$emit('update:is-new-report-sidebar-active', false);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'تم التصدير بنجاح',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              });

              // Check the fileType and set the appropriate filename and content type
              const { fileType } = data;
              let filename; let
                contentType;

              if (fileType === 'pdf') {
                filename = 'evaluations.pdf';
                contentType = 'application/pdf';
              } else if (fileType === 'excel') {
                filename = 'evaluations.xlsx';
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
              }

              // Call the download function with the correct parameters
              setTimeout(() => {
                this.download(response.data, filename, contentType);
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
              let message = 'حدث خطأ أثناء  التصدير';
              if (error.response) {
                if (error.response.status === 422) {
                  this.$refs.refFormObserver.setErrors(
                    error.response.data.errors,
                  );
                  message = error.response.data.message;
                } else {
                  message = error.response.data.error;
                }
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            });
        }
      });
    },
    download(data, filename, type) {
      // xlxs
      const blob = new Blob([data], { type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      // Set a delay before revoking the URL
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a); // Optional: Clean up by removing the <a> element
      }, 100); // Delay in milliseconds
    },
  },
  setup(props, { emit }) {
    const blankExportData = {
      features: '',
      fileType: '',
    };
    const fileTypes = ref(['pdf', 'excel']);

    const exportData = ref(JSON.parse(JSON.stringify(blankExportData)));
    const resetEvaluationData = () => {
      exportData.value = JSON.parse(JSON.stringify(blankExportData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetEvaluationData);
    return {
      exportData,
      fileTypes,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#new-report-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
